import * as FontFaceObserver from 'fontfaceobserver';
import * as constants from '@pixi/constants';

import { Container, Graphics, Renderer, Sprite, Text, TextStyleAlign, Texture, utils } from 'pixi.js';

import Asset from '@/model/entity/asset/asset';
import AssetService from '../asset/asset-service';
import { CardSide } from './card-service';
import ChimneySmokeEffect from '@/effects/particle/smoke/chimney-effect';
import Decimal from 'decimal.js-light';
import Element from '@/model/entity/card/element';
import { Emitter } from '@pixi/particle-emitter';
import MathsHelper from '@/model/helper/maths-helper';
import Page from '@/model/entity/card/page';
import ParticleEffectInterface from '@/effects/particle/particle-effect-interface';
import PetalsEffect from '@/effects/particle/nature/petals-effect';
import SnowEffect from '@/effects/particle/weather/snow-effect';
import SnowThickEffect from '@/effects/particle/weather/snow-thick-effect';
import { settings } from '@pixi/settings';

settings.SCALE_MODE = constants.SCALE_MODES.LINEAR;

export default class PixiRendererService
{
    private page!: Page;
    private side!: CardSide;
    private templateOverrides!: any;
    private width!: number;
    private height!: number;

    private canvas!: HTMLCanvasElement|undefined;
    private pixi!: Renderer;
    private stage!: Container;

    private pixiMappings: {
        layers: {[id: string]: Container},
        elements: {[id: string]: Container|Sprite},
        emitters: {[id: string]: Emitter},
    } = {
            layers: {},
            elements: {},
            emitters: {},
        };

    public constructor(
        page: Page,
        side: CardSide,
        templateOverrides: object,
        width: number,
        height: number,
        canvas?: HTMLCanvasElement,
    )
    {
        this.page = page;
        this.side = side;
        this.templateOverrides = templateOverrides;
        this.width = width;
        this.height = height;
        this.canvas = canvas;
    }

    public getCanvas(): HTMLCanvasElement|undefined
    {
        return this.canvas;
    }

    public getRenderer(): Renderer
    {
        return this.pixi;
    }

    /**
     * Initialises pixi
     */

    public async init()
    {
        if (!this.canvas)
        {
            this.canvas = document.createElement('canvas');
        }

        this.pixi = new Renderer({
            view: this.canvas,
            width: this.width,
            height: this.height,
            backgroundAlpha: 0,
            resolution: this.width < 1024 ? 2 : 1,
        });

        this.stage = new Container();

        await this.addPixiLayers();
    }

    /**
     * Re-initialises pixi
     */

    public async reinit()
    {
        // Destroy all emitters
        for (const id in this.pixiMappings.emitters)
        {
            const emitter = this.pixiMappings.emitters[id];
            emitter.destroy();
        }

        // Destroy all the layers
        for (const id in this.pixiMappings.layers)
        {
            const layer = this.pixiMappings.layers[id];
            layer.destroy(true);
        }

        // Remove the current mappings
        this.pixiMappings = {
            layers: {},
            elements: {},
            emitters: {},
        };

        // Add all the layers back
        this.addPixiLayers();
    }

    /**
     * Deinitialises pixi
     */

    public async deinit()
    {
        this.pixi.destroy(true);
    }

    /**
     * Called when the scene should be rendered
     *
     * @param deltaMs
     */

    public onUpdate(deltaMs: number)
    {
        this.pixi.render(this.stage);

        for (const emitterId in this.pixiMappings.emitters)
        {
            const emitter = this.pixiMappings.emitters[emitterId];
            emitter.update(deltaMs * 0.001);
        }
    }

    /**
     * Adds the layers to the pixi stage
     */

    private async addPixiLayers()
    {
        for (const layer of this.page.layers)
        {
            if (layer.side !== this.side)
            {
                continue;
            }

            // Create the layer
            const pixiLayer = new Container();
            pixiLayer.x = 0;
            pixiLayer.y = 0;

            this.stage.addChild(pixiLayer);

            // Save the mapping
            this.pixiMappings.layers[layer.id] = pixiLayer;

            // Add layer elements
            for (const element of layer.elements)
            {
                await this.addPixiLayerElement(pixiLayer, element);
            }
        }
    }

    /**
     * Adds an element to a pixi layer
     *
     * @param layer
     * @param element
     */

    private async addPixiLayerElement(layer: Container, element: Element)
    {
        switch (element.type)
        {
        case 'effect':
            await this.addPixiLayerEffect(layer, element);
            break;

        case 'image':
            await this.addPixiLayerImage(layer, element);
            break;

        case 'text':
            await this.addPixiLayerText(layer, element);
            break;
        }
    }

    /**
     * Adds an effect to a pixi layer
     *
     * @param pixi
     * @param layer
     * @param element
     */

    private async addPixiLayerEffect(layer: Container, element: Element)
    {
        if (!element.config.effect)
        {
            return;
        }

        const container = new Container();
        container.x = this.getElementX(element);
        container.y = this.getElementY(element);

        layer.addChild(container);

        switch (element.config.effect.id)
        {
        case 'particle/nature/petals':
            this.addPixiLayerParticleEffect(
                container, element, new PetalsEffect(),
            );
            break;

        case 'particle/smoke/chimney':
            this.addPixiLayerParticleEffect(
                container, element, new ChimneySmokeEffect(),
            );
            break;

        case 'particle/weather/snow':
            this.addPixiLayerParticleEffect(
                container, element, new SnowEffect(),
            );
            break;

        case 'particle/weather/snow-thick':
            this.addPixiLayerParticleEffect(
                container, element, new SnowThickEffect(),
            );
            break;

        default:
        }
    }

    /**
     * Adds a particle effect to a pixi layer
     *
     * @param pixi
     * @param layer
     * @param element
     * @param effect
     */

    private async addPixiLayerParticleEffect(
        layer: Container, element: Element, effect: ParticleEffectInterface,
    )
    {
        // Load emitter
        const emitter = new Emitter(layer, effect.getParticlesConfig(this.width, this.height));

        this.pixiMappings.emitters[element.id] = emitter;

        // Start emitting
        emitter.emit = true;

        // Pre-warm
        const preWarmTime = effect.getPreWarmTime();
        if (preWarmTime > 0)
        {
            emitter.update(preWarmTime);
        }
    }

    /**
     * Adds an image to a pixi layer
     *
     * @param pixi
     * @param layer
     * @param element
     */

    private async addPixiLayerImage(layer: Container, element: Element)
    {
        if (!element.asset?.path && !element.url)
        {
            return;
        }

        // Load the sprite
        let sprite: Sprite;

        if (element.url)
        {
            console.log('Loading sprite from URL', element.url);
            sprite = await this.loadSpriteFromUrl(element.url);
        }
        else if (element.asset)
        {
            sprite = await this.loadSpriteFromAsset(element.asset);
        }
        else
        {
            return;
        }

        // Set position / size
        const x1 = this.getElementX(element);
        const y1 = this.getElementY(element);
        const maxWidth = this.getElementWidth(element);
        const maxHeight = this.getElementHeight(element);

        let fit: {width: number, height: number};

        switch (element.config.fit)
        {
        case 'contain':

            fit = MathsHelper.containFit(
                sprite.texture.width,
                sprite.texture.height,
                maxWidth,
                maxHeight,
            );

            switch (element.config.align.horizontal)
            {
            case 'left':
                sprite.x = x1;
                break;

            case 'right':
                sprite.x = x1 + (maxWidth - fit.width);
                break;

            case 'centre':
            default:
                sprite.x = x1 + ((maxWidth - fit.width) / 2);
                break;
            }

            switch (element.config.align.vertical)
            {
            case 'top':
                sprite.y = y1;
                break;

            case 'bottom':
                sprite.y = y1 + (maxHeight - fit.height);
                break;

            case 'middle':
            default:
                sprite.y = y1 + ((maxHeight - fit.height) / 2);
                break;
            }

            sprite.width = fit.width;
            sprite.height = fit.height;

            break;

        case 'cover':

            fit = MathsHelper.coverFit(
                sprite.texture.width,
                sprite.texture.height,
                maxWidth,
                maxHeight,
            );

            sprite.x = x1 + ((maxWidth - fit.width) / 2);
            sprite.y = y1 + ((maxHeight - fit.height) / 2);
            sprite.width = fit.width;
            sprite.height = fit.height;

            break;

        case 'fill':
        default:

            sprite.x = x1;
            sprite.y = y1;
            sprite.width = maxWidth;
            sprite.height = maxHeight;
        }

        // Add to the layer
        layer.addChild(sprite);
    }

    /**
     * Adds text to a pixi layer
     *
     * @param layer
     * @param element
     */

    private async addPixiLayerText(layer: Container, element: Element)
    {
        if (!element.config.text)
        {
            return;
        }

        const width = this.getElementWidth(element);
        const height = this.getElementHeight(element);

        // Add a container for the text to which the text can be positioned within
        const container = new Container();
        container.x = this.getElementX(element);
        container.y = this.getElementY(element);

        layer.addChild(container);

        // Add a background colour if required
        if (element.config.background?.colour)
        {
            const background = new Graphics();
            background.x = 0;
            background.y = 0;
            background.beginFill(this.getPixiColour(element.config.background.colour, false));
            background.drawRect(
                0, 0, width, height,
            );
            background.alpha = this.getPixiAlpha(element.config.background.colour);

            container.addChild(background);
        }

        // Load the font
        let fontFamily: string;
        if (element.config.text.font)
        {
            fontFamily = element.config.text.font;

            // Ensure the font is loaded
            if (![ 'Arial', 'Verdana' ].includes(fontFamily))
            {
                const font = new FontFaceObserver.default(element.config.text.font);
                await font.load();
            }
        }
        else
        {
            fontFamily = 'Arial';
        }

        // Add the text
        const textValue = this.templateOverrides[element.id] ?? element.config.text.text;

        const text = new Text(textValue, {
            fontFamily,
            fontSize: this.getElementTextSize(element),
            fill: this.getPixiColour(element.config.text.colour),
            align: this.getElementTextAlign(element),
            wordWrap: true,
            wordWrapWidth: width,
            padding: 14,
            dropShadow: element.config.text.dropShadow,
            dropShadowColor: element.config.text.dropShadowColour,
            dropShadowBlur: element.config.text.dropShadowBlur,
            dropShadowDistance: element.config.text.dropShadowDistance,
        });

        text.resolution = 1;

        // Set the text's position
        this.setPixiTextPosition(text, element);

        // Add to the container
        container.addChild(text);
    }

    /**
     * Sets the position of a Pixi text element
     *
     * @param text
     * @param element
     */

    private setPixiTextPosition(text: Text, element: Element)
    {
        const width = this.getElementWidth(element);
        const height = this.getElementHeight(element);

        let anchorX: number;
        let anchorY: number;
        let x: number;
        let y: number;

        switch (element.config.align.horizontal)
        {
        case 'left':
            anchorX = 0;
            x = 0;
            break;

        case 'right':
            anchorX = 1;
            x = width;
            break;

        case 'centre':
        default:
            anchorX = 0.5;
            x = width / 2;
            break;
        }

        switch (element.config.align.vertical)
        {
        case 'top':
            anchorY = 0;
            y = 0;
            break;

        case 'bottom':
            anchorY = 1;
            y = height;
            break;

        case 'middle':
        default:
            anchorY = 0.5;
            y = height / 2;
            break;
        }

        text.anchor.set(anchorX, anchorY);
        text.x = x;
        text.y = y;
    }

    /**
     * Loads a sprite from an asset
     *
     * @param path
     */

    private async loadSpriteFromAsset(asset: Asset): Promise<Sprite>
    {
        const path = AssetService.getAssetFileUrl(asset);

        return this.loadSpriteFromUrl(path);
    }

    /**
     * Loads a sprite from a URL
     *
     * @param url
     */

    private async loadSpriteFromUrl(url: string): Promise<Sprite>
    {
        // Load the texture
        const texture = Texture.from(url);
        const sprite = new Sprite(texture);

        // Wait for the texture to load before returning
        if (texture.baseTexture.valid)
        {
            return sprite;
        }

        return new Promise(resolve =>
        {
            texture.addListener('update', () =>
            {
                resolve(sprite);
            });
        });
    }

    /**
     * Returns the actual x of the element based on the canvas size
     *
     * @param element
     */

    private getElementX(element: Element): number
    {
        return new Decimal(element.config.position.x1)
            .div(100)
            .mul(this.width)
            .toNumber();
    }

    /**
     * Returns the actual y of the element based on the canvas size
     *
     * @param element
     */

    private getElementY(element: Element): number
    {
        return new Decimal(element.config.position.y1)
            .div(100)
            .mul(this.height)
            .toNumber();
    }

    /**
     * Returns the actual width of the element based on the canvas size
     *
     * @param element
     */

    private getElementWidth(element: Element): number
    {
        return new Decimal(element.config.position.x2).minus(element.config.position.x1)
            .div(100)
            .mul(this.width)
            .toNumber();
    }

    /**
     * Returns the actual height of the element based on the canvas size
     *
     * @param element
     */

    private getElementHeight(element: Element): number
    {
        return new Decimal(element.config.position.y2).minus(element.config.position.y1)
            .div(100)
            .mul(this.height)
            .toNumber();
    }

    /**
     * Returns the text size for an element
     *
     * @param element
     * @returns
     */

    private getElementTextSize(element: Element): number
    {
        const percentage: number = element.config.text?.size ? new Decimal(element.config.text.size).toNumber() : 5;

        return new Decimal(this.height).div(100)
            .mul(percentage)
            .toNumber();
    }

    /**
     * Returns the text alignmnent for an element
     *
     * @param element
     */

    private getElementTextAlign(element: Element): TextStyleAlign
    {
        switch (element.config.align.horizontal)
        {
        case 'centre':
            return 'center';

        case 'left':
            return 'left';

        case 'right':
            return 'right';

        default:
            return 'center';
        }
    }

    /**
     * Returns a Pixi compatible colour from a HTML colour
     *
     * @param htmlColour
     * @param includeAlpha
     * @returns
     */

    private getPixiColour(htmlColour: string|null, includeAlpha = true): number
    {
        if (!htmlColour)
        {
            return 0x000000;
        }

        if (includeAlpha)
        {
            return utils.string2hex(htmlColour);
        }
        else
        {
            return utils.string2hex(htmlColour.substr(0, 7));
        }
    }

    /**
     * Returns a pixi compatible alpha value from a HTML colour
     *
     * @param htmlColour
     */

    private getPixiAlpha(htmlColour: string|null): number
    {
        if (!htmlColour)
        {
            return 1;
        }

        if (htmlColour.length === 7)
        {
            return 1;
        }
        else
        {
            const hexAlpha = htmlColour.substr(7, 2);
            const intAlpha = parseInt(hexAlpha, 16);

            const alpha = new Decimal(intAlpha).div(255)
                .toNumber();

            return alpha;
        }
    }
}