var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card",class:{
        'card--flip-turn': !_vm.bookLike,
        'card--page-turn': _vm.bookLike,
        'card--open': _vm.currentPage > 1 || _vm.showingSide === 'back',
        'card--side-back': !_vm.bookLike && _vm.showingSide === 'back',
        'card--end': _vm.currentPage === _vm.totalPages && _vm.showingSide === 'back'
    }},[_vm._l((_vm.cardTemplate.pages),function(page){return (_vm.cardTemplate)?_c('app-page',{key:page.id,style:('z-index:' + _vm.getPageZIndex(page)),attrs:{"page":page,"templateOverrides":_vm.cardTemplateOverrides,"static":_vm.static,"turned":_vm.getPageTurned(page)},on:{"side-clicked":_vm.turnPage}}):_vm._e()}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.static),expression:"!static"}],staticClass:"card__pagination"},[_c('v-btn',{staticClass:"card__pagination__left-arrow",attrs:{"text":""},on:{"click":function($event){return _vm.turnPage('back')}}},[_c('v-icon',[_vm._v("mdi-chevron-double-left")]),_vm._v(" Prev ")],1),_c('v-btn',{staticClass:"card__pagination__right-arrow",attrs:{"text":""},on:{"click":function($event){return _vm.turnPage('front')}}},[_vm._v(" Next "),_c('v-icon',[_vm._v("mdi-chevron-double-right")])],1)],1),(_vm.audioFileUrl)?_c('audio',{attrs:{"autoplay":""}},[_c('source',{attrs:{"src":_vm.audioFileUrl,"type":"audio/mpeg"}})]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }