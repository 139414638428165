import { Component, Prop, Vue } from 'vue-property-decorator';

import AssetService from '@/model/service/asset/asset-service';
import Card from '@/model/entity/card/card';
import Element from '@/model/entity/card/element';
import Page from '@/model/entity/card/page';
import PageComponent from '@/components/page/Page.vue';
import Template from '@/model/entity/card/template';

@Component({
    components:
    {
        'app-page': PageComponent,
    },
})
export default class CardComponent extends Vue
{
    @Prop()
    private card!: Card;

    @Prop()
    private template!: Template;

    @Prop({
        type: Boolean,
        default: false,
    })
    private bookLike!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    private static!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    private edit!: boolean;

    private created()
    {
        // Trigger an event with the starting page
        this.$emit(
            'page', this.currentPage, this.showingSide,
        );
    }

    private get cardTemplate(): Template|null
    {
        if (this.card?.template)
        {
            return this.card.template;
        }
        else if (this.template)
        {
            return this.template;
        }
        else
        {
            return null;
        }
    }

    private get cardTemplateOverrides(): any
    {
        if (this.card)
        {
            return this.card.templateOverrides;
        }
        else
        {
            return {};
        }
    }

    private currentPage = 1;
    private showingSide = 'front';

    /**
     * Returns the total number of pages
     */

    private get totalPages(): number
    {
        if (!this.cardTemplate)
        {
            return 0;
        }

        return this.cardTemplate.pages.length;
    }

    /**
     * Returns the audio file URL, if any exists
     */

    private get audioFileUrl(): string|null
    {
        if (!this.cardTemplate)
        {
            return null;
        }

        let audioElement: Element|null = null;

        for (const page of this.cardTemplate.pages)
        {
            for (const layer of page.layers)
            {
                for (const element of layer.elements)
                {
                    if (element.type === 'audio')
                    {
                        audioElement = element;
                        break;
                    }
                }
            }
        }

        return audioElement?.asset ? AssetService.getAssetFileUrl(audioElement.asset) : null;
    }

    /**
     * Returns whether the given page has been turned
     *
     * @param page
     * @returns
     */

    private getPageTurned(page: Page)
    {
        if (this.currentPage > page.position || (this.currentPage === page.position && this.showingSide === 'back'))
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    /**
     * Returns the z-index for the given page
     *
     * @param page
     * @returns
     */

    private getPageZIndex(page: Page)
    {
        return this.currentPage >= page.position ? page.position + 2 : this.totalPages - page.position;
    }

    /**
     * Returns whether a page has visible content
     *
     * @param page
     * @param side
     * @returns
     */

    private sideHasContent(page: Page, side: string): boolean
    {
        for (const layer of page.layers)
        {
            if (layer.side !== side)
            {
                continue;
            }

            for (const element of layer.elements)
            {
                switch (element.type)
                {
                case 'audio':
                case 'effect':
                case 'image':
                case 'video':
                    return true;

                case 'text':
                    // Check overrides
                    if (element.id in this.cardTemplateOverrides)
                    {
                        // eslint-disable-next-line max-depth
                        if (this.cardTemplateOverrides[element.id])
                        {
                            return true;
                        }
                    }

                    // Has text
                    else if (element.config.text?.text)
                    {
                        return true;
                    }

                    break;

                default:
                }
            }
        }

        return false;
    }

    /**
     * Turns the page appropriately based on the side that was clicked and the current side being shown
     *
     * @param side
     */

    private turnPage(side: string)
    {
        if (this.static || !this.cardTemplate)
        {
            return;
        }

        if (this.showingSide === 'front' && side === 'front')
        {
            // If there's no content on the back side, go straight to the next page
            if (this.edit || this.currentPage === this.totalPages || this.sideHasContent(this.cardTemplate.pages[this.currentPage - 1], 'back'))
            {
                this.showingSide = 'back';
            }
            else if (this.currentPage < this.totalPages)
            {
                this.turnPageLeft();
            }
        }
        else if (this.showingSide === 'back' && side === 'back')
        {
            if (this.bookLike)
            {
                if (this.currentPage === this.totalPages)
                {
                    this.turnPageRight();
                }
                else
                {
                    this.showingSide = 'front';
                }
            }
            else
            {
                this.showingSide = 'front';
            }
        }
        else if (side === 'front')
        {
            this.turnPageLeft();

            if (!this.bookLike)
            {
                this.showingSide = 'front';
            }
        }
        else if (side === 'back')
        {
            this.turnPageRight();

            if (this.bookLike)
            {
                this.showingSide = 'front';
            }
            else if (this.edit || this.sideHasContent(this.cardTemplate.pages[this.currentPage - 1], 'back'))
            {
                this.showingSide = 'back';
            }
            else
            {
                this.showingSide = 'front';
            }
        }

        // Trigger an event with the new page
        this.$emit(
            'page', this.currentPage, this.showingSide,
        );
    }

    /**
     * Turns the page to the left
     */

    private turnPageLeft()
    {
        if (this.static)
        {
            return;
        }

        if (this.currentPage > this.totalPages)
        {
            return;
        }

        this.currentPage++;
    }

    /**
     * Turns the page to the right
     */

    private turnPageRight()
    {
        if (this.static)
        {
            return;
        }

        if (this.currentPage <= 1)
        {
            return;
        }

        this.currentPage--;
    }
}