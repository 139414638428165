import AbstractAuthenticatedService from '../abstract-authenticated-service';
import DeleteElementTask from '@/model/task/card/delete-element';
import DeleteLayerTask from '@/model/task/card/delete-layer';
import DeletePageTask from '@/model/task/card/delete-page';
import Element from '@/model/entity/card/element';
import GetTemplateTask from '@/model/task/card/get-template';
import GetTemplatesTask from '@/model/task/card/get-templates';
import Layer from '@/model/entity/card/layer';
import Page from '@/model/entity/card/page';
import PostElementTask from '@/model/task/card/post-element';
import PostLayerTask from '@/model/task/card/post-layer';
import PostPageTask from '@/model/task/card/post-page';
import PostTemplateTask from '@/model/task/card/post-template';
import PutElementTask from '@/model/task/card/put-element';
import PutLayerTask from '@/model/task/card/put-layer';
import PutPageTask from '@/model/task/card/put-page';
import PutTemplateTask from '@/model/task/card/put-template';
import Template from '@/model/entity/card/template';
import TemplateSearchConditionsInterface from '@/model/interface/card/template-search-conditions-interface';
import TemplateSearchResultsInterface from '@/model/interface/card/template-search-results-interface';

export type ElementType = 'audio' | 'effect' | 'image' | 'text' | 'video';

export default class TemplateService extends AbstractAuthenticatedService
{
    /**
     * Finds matching templates
     *
     * @param searchConditions
     */

    public async findTemplates(searchConditions: TemplateSearchConditionsInterface|null): Promise<TemplateSearchResultsInterface>
    {
        const task = new GetTemplatesTask();

        if (searchConditions)
        {
            task.searchConditions = searchConditions;
        }

        return task.run();
    }

    /**
     * Loads a template by id
     *
     * @param id
     */

    public async loadTemplate(id: string): Promise<Template>
    {
        const task = new GetTemplateTask();
        task.id = id;

        return task.run();
    }

    /**
     * Creates a template
     *
     * @param template
     * @returns
     */

    public async createTemplate(template: Template): Promise<Template>
    {
        const task = new PostTemplateTask();
        task.template = template;

        return this.runAuthenticatedTask(task) as Promise<Template>;
    }

    /**
     * Saves a template
     *
     * @param template
     * @returns
     */

    public async saveTemplate(template: Template): Promise<Template>
    {
        const task = new PutTemplateTask();
        task.template = template;

        return this.runAuthenticatedTask(task) as Promise<Template>;
    }

    /**
     * Saves the changes to a template
     *
     * @param template
     */

    public async saveTemplateChanges(template: Template, originalTemplate: Template|null = null): Promise<Template>
    {
        let savedTemplate: Template;

        if (originalTemplate)
        {
            // Check for template property updates
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const originalDto: any = Object.assign({}, originalTemplate.dto);
            delete originalDto.pages;

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const newDto: any = Object.assign({}, template.dto);
            delete newDto.pages;

            if (JSON.stringify(newDto) === JSON.stringify(originalDto))
            {
                savedTemplate = template;
            }
            else
            {
                savedTemplate = await this.saveTemplate(template);
            }
        }
        else
        {
            savedTemplate = await this.createTemplate(template);

            // Update child pages with the new template
            for (const page of template.pages)
            {
                page.template = savedTemplate;
                template.updatePage(page);
            }
        }

        // Check for new or updated pages
        for (const page of template.pages)
        {
            let found = false;
            let originalPage: Page|null = null;

            if (originalTemplate)
            {
                for (originalPage of originalTemplate.pages)
                {
                    if (originalPage.id === page.id)
                    {
                        found = true;
                        break;
                    }
                }
            }

            // Update if the page previously existed
            if (found)
            {
                await this.savePageChanges(page, originalPage);
            }

            // Create if the page didn't exist
            else
            {
                await this.savePageChanges(page, null);
            }
        }

        // Check for deleted pages
        if (originalTemplate)
        {
            for (const originalPage of originalTemplate.pages)
            {
                let found = false;

                for (const newPage of template.pages)
                {
                    if (newPage.id === originalPage.id)
                    {
                        found = true;
                        break;
                    }
                }

                if (found)
                {
                    continue;
                }

                // Delete the page
                await this.deletePage(originalPage.id);
            }
        }

        // Reload the updated template
        return this.loadTemplate(savedTemplate.id);
    }

    /**
     * Creates a page
     *
     * @param page
     * @returns
     */

    public async createPage(page: Page): Promise<Page>
    {
        const task = new PostPageTask();
        task.page = page;

        return this.runAuthenticatedTask(task) as Promise<Page>;
    }

    /**
     * Saves a page
     *
     * @param page
     * @returns
     */

    public async savePage(page: Page): Promise<Page>
    {
        const task = new PutPageTask();
        task.page = page;

        return this.runAuthenticatedTask(task) as Promise<Page>;
    }

    /**
     * Saves the changes to a page
     *
     * @param page
     * @param originalPage
     */

    public async savePageChanges(page: Page, originalPage: Page|null = null): Promise<Page>
    {
        let savedPage: Page;

        if (originalPage)
        {
            // Check for page property updates
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const originalDto: any = Object.assign({}, originalPage.dto);
            delete originalDto.template;
            delete originalDto.layers;

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const newDto: any = Object.assign({}, page.dto);
            delete newDto.template;
            delete newDto.layers;

            if (JSON.stringify(newDto) === JSON.stringify(originalDto))
            {
                savedPage = page;
            }
            else
            {
                savedPage = await this.savePage(page);
            }
        }
        else
        {
            savedPage = await this.createPage(page);

            // Update child layers with the new page
            for (const layer of page.layers)
            {
                layer.page = savedPage;
                page.updateLayer(layer);
            }
        }

        // Check for new or updated layers
        for (const layer of page.layers)
        {
            let found = false;
            let originalLayer: Layer|null = null;

            if (originalPage)
            {
                for (originalLayer of originalPage.layers)
                {
                    if (originalLayer.id === layer.id)
                    {
                        found = true;
                        break;
                    }
                }
            }

            // Update if the layer previously existed
            if (found)
            {
                await this.saveLayerChanges(layer, originalLayer);
            }

            // Create if the layer didn't exist
            else
            {
                await this.saveLayerChanges(layer, null);
            }
        }

        // Check for deleted layers
        if (originalPage)
        {
            for (const originalLayer of originalPage.layers)
            {
                let found = false;

                for (const newLayer of page.layers)
                {
                    if (newLayer.id === originalLayer.id)
                    {
                        found = true;
                        break;
                    }
                }

                if (found)
                {
                    continue;
                }

                // Delete the layer
                await this.deleteLayer(originalLayer.id);
            }
        }

        return savedPage;
    }

    /**
     * Deletes a page
     *
     * @param id
     * @returns
     */

    public async deletePage(id: string): Promise<void>
    {
        const task = new DeletePageTask();
        task.id = id;

        return this.runAuthenticatedTask(task) as Promise<void>;
    }

    /**
     * Creates a layer
     *
     * @param layer
     * @returns
     */

    public async createLayer(layer: Layer): Promise<Layer>
    {
        const task = new PostLayerTask();
        task.layer = layer;

        return this.runAuthenticatedTask(task) as Promise<Layer>;
    }

    /**
     * Saves a layer
     *
     * @param layer
     * @returns
     */

    public async saveLayer(layer: Layer): Promise<Layer>
    {
        const task = new PutLayerTask();
        task.layer = layer;

        return this.runAuthenticatedTask(task) as Promise<Layer>;
    }

    /**
     * Saves the changes to a layer
     *
     * @param layer
     * @param originalLayer
     */

    public async saveLayerChanges(layer: Layer, originalLayer: Layer|null = null): Promise<Layer>
    {
        let savedlayer: Layer;

        if (originalLayer)
        {
            // Check for layer property updates
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const originalDto: any = Object.assign({}, originalLayer.dto);
            delete originalDto.page;
            delete originalDto.elements;

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const newDto: any = Object.assign({}, layer.dto);
            delete newDto.page;
            delete newDto.elements;

            if (JSON.stringify(newDto) === JSON.stringify(originalDto))
            {
                savedlayer = layer;
            }
            else
            {
                savedlayer = await this.saveLayer(layer);
            }
        }
        else
        {
            savedlayer = await this.createLayer(layer);

            // Update child elements with the new page
            for (const element of layer.elements)
            {
                element.layer = savedlayer;
                layer.updateElement(element);
            }
        }

        // Check for new or updated elements
        for (const element of layer.elements)
        {
            let found = false;
            let originalElement: Element|null = null;

            if (originalLayer)
            {
                for (originalElement of originalLayer.elements)
                {
                    if (originalElement.id === element.id)
                    {
                        found = true;
                        break;
                    }
                }
            }

            // Update if the element previously existed
            if (found)
            {
                await this.saveElementChanges(element, originalElement);
            }

            // Create if the element didn't exist
            else
            {
                await this.saveElementChanges(element, null);
            }
        }

        // Check for deleted elements
        if (originalLayer)
        {
            for (const originalElement of originalLayer.elements)
            {
                let found = false;

                for (const newElement of layer.elements)
                {
                    if (newElement.id === originalElement.id)
                    {
                        found = true;
                        break;
                    }
                }

                if (found)
                {
                    continue;
                }

                // Delete the element
                await this.deleteElement(originalElement.id);
            }
        }

        return savedlayer;
    }

    /**
     * Deletes a layer
     *
     * @param id
     * @returns
     */

    public async deleteLayer(id: string): Promise<void>
    {
        const task = new DeleteLayerTask();
        task.id = id;

        return this.runAuthenticatedTask(task) as Promise<void>;
    }

    /**
     * Creates a element
     *
     * @param element
     * @returns
     */

    public async createElement(element: Element): Promise<Element>
    {
        const task = new PostElementTask();
        task.element = element;

        return this.runAuthenticatedTask(task) as Promise<Element>;
    }

    /**
      * Saves a element
      *
      * @param element
      * @returns
      */

    public async saveElement(element: Element): Promise<Element>
    {
        const task = new PutElementTask();
        task.element = element;

        return this.runAuthenticatedTask(task) as Promise<Element>;
    }

    /**
     * Saves the changes to a element
     *
     * @param element
     * @param originalElement
     */

    public async saveElementChanges(element: Element, originalElement: Element|null = null): Promise<Element>
    {
        let savedElement: Element;

        if (originalElement)
        {
            // Check for element property updates
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const originalDto: any = Object.assign({}, originalElement.dto);
            delete originalDto.layer;

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const newDto: any = Object.assign({}, element.dto);
            delete newDto.layer;

            if (JSON.stringify(newDto) === JSON.stringify(originalDto))
            {
                savedElement = element;
            }
            else
            {
                savedElement = await this.saveElement(element);
            }
        }
        else
        {
            savedElement = await this.createElement(element);
        }

        return savedElement;
    }

    /**
     * Deletes an element
     *
     * @param id
     * @returns
     */

    public async deleteElement(id: string): Promise<void>
    {
        const task = new DeleteElementTask();
        task.id = id;

        return this.runAuthenticatedTask(task) as Promise<void>;
    }
}