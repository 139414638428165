import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import AssetService from '@/model/service/asset/asset-service';
import Card from '@/model/entity/card/card';
import CardRendererService from '@/model/service/card/card-renderer-service';
import Element from '@/model/entity/card/element';
import Template from '@/model/entity/card/template';
import { markRaw } from 'vue';

@Component
export default class Card3dComponent extends Vue
{
    @Prop()
    private card!: Card;

    @Prop()
    private template!: Template;

    @Prop({
        type: Boolean,
        default: false,
    })
    private bookLike!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    private static!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    private edit!: boolean;

    public $refs: any = {
        scene: HTMLDivElement,
    };

    private cardRendererService: CardRendererService|null = null;
    private sceneCreated = false;
    private cardCreated = false;

    mounted()
    {
        if (this.$refs.scene)
        {
            this.createScene();
        }
    }

    beforeMount()
    {
        window.addEventListener('keydown', this.handleKeydown);
    }

    beforeDestroy()
    {
        window.removeEventListener('keydown', this.handleKeydown);
    }

    handleKeydown(e: KeyboardEvent)
    {
        switch (e.keyCode)
        {
        case 37:
            this.onClosePage();
            break;
        case 39:
            this.onOpenPage();
            break;
        }
    }

    beforeUnmount()
    {
        this.cardRendererService?.destroy();
        this.cardRendererService = null;
    }

    @Watch('card')
    private onCardChange()
    {
        if (this.sceneCreated && !this.cardCreated)
        {
            this.cardRendererService?.init(
                this.card,
                this.cardTemplate,
                this.cardTemplateOverrides,
            );

            this.cardCreated = true;
        }
    }

    public onOpenPage()
    {
        this.cardRendererService?.openPage();
    }

    public onClosePage()
    {
        this.cardRendererService?.closePage();
    }

    private async createScene()
    {
        if (this.cardRendererService)
        {
            return;
        }

        this.cardRendererService = markRaw(new CardRendererService(
            this.$refs.scene,
            this.$serviceContainer,
            () =>
            {
                this.onSceneCreated();
            },
        ));
    }

    private onSceneCreated()
    {
        this.sceneCreated = true;

        if (!this.cardCreated && this.card)
        {
            this.cardRendererService?.init(
                this.card,
                this.cardTemplate,
                this.cardTemplateOverrides,
            );

            this.cardCreated = true;
        }
    }

    private get cardTemplate(): Template|null
    {
        if (this.card?.template)
        {
            return this.card.template;
        }
        else if (this.template)
        {
            return this.template;
        }
        else
        {
            return null;
        }
    }

    private get cardTemplateOverrides(): any
    {
        if (this.card)
        {
            return this.card.templateOverrides;
        }
        else
        {
            return {};
        }
    }
}