import { ActionEvent, ActionManager, Animation, ArcRotateCamera, BezierCurveEase, Color3, Color4, DefaultRenderingPipeline, DirectionalLight, DynamicTexture, EasingFunction, Engine, ExecuteCodeAction, IEnvironmentHelperOptions, Mesh, MeshBuilder, Path2, PointLight, PolygonMeshBuilder, PredicateCondition, Quaternion, Scene, ShadowGenerator, Sound, SpotLight, StandardMaterial, Texture, ThinBlurPostProcess, Tools, TransformNode, UploadLevelsAsync, Vector2, Vector3 } from '@babylonjs/core';

import AssetService from '../asset/asset-service';
import Card from '@/model/entity/card/card';
import { CardSide } from './card-service';
import Element from '@/model/entity/card/element';
import ElementDto from '@/model/entity/card/element-dto';
import { Inspector } from '@babylonjs/inspector';
import LayerDto from '@/model/entity/card/layer-dto';
import Page from '@/model/entity/card/page';
import PageDto from '@/model/entity/card/page-dto';
import PixiRendererService from './pixi-renderer-service';
import ServiceContainer from '../service-container';
import Template from '@/model/entity/card/template';
import earcut from 'earcut';
import { format } from 'date-fns';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const paperTextureImage = require('@/assets/images/textures/paper/handmadepaper.png');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const christmasStampImage = require('@/assets/images/stamps/christmas.svg');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const defaultStampImage = require('@/assets/images/stamps/london.svg');

export default class CardRendererService
{
    private static readonly PAGE_WIDTH = 14.8;
    private static readonly PAGE_HEIGHT = 21;

    private static readonly PAGE_WIDTH_PIXELS = 1480;
    private static readonly PAGE_HEIGHT_PIXELS = 2100;

    private static readonly ANIMATION_FRAME_RATE = 30;
    private static readonly PAGE_TRANSITION_DURATION = 1;
    private static readonly FLAP_TRANSITION_DURATION = 1;

    private serviceContainer!: ServiceContainer;

    private card!: Card;
    private template!: Template|null;
    private templateOverrides!: any;
    private currentPage = 1;
    private currentSide: CardSide = 'front';

    private containerNode!: TransformNode;
    private envelopeNode!: TransformNode;
    private flapNode!: TransformNode;
    private cardNode!: TransformNode;
    private pageNodes: {[pageNumber: number]: TransformNode} = {};

    private container!: HTMLDivElement;
    private resizeObserver: ResizeObserver|null = null;

    private canvas!: HTMLCanvasElement;
    private engine!: Engine;
    private scene!: Scene;
    private camera!: ArcRotateCamera;

    private images: {[key: string]: string} = {};
    private materials: {[key: string]: StandardMaterial} = {};
    private textures: {[key: string]: Texture} = {};
    private paperMaterial!: StandardMaterial;

    private pixiRenderers: {
        renderer: PixiRendererService,
        texture: DynamicTexture,
    }[] = [];

    private envelopeFrontToBackAnimation!: Animation;
    private envelopeBackToFrontAnimation!: Animation;
    private envelopeRemovalAnimation!: Animation;
    private flapClosedToOpenAnimation!: Animation;
    private flapOpenToClosedAnimation!: Animation;

    private cardTurnUprightAnimation!: Animation;

    private pageClosedToOpenAnimation!: Animation;
    private pageOpenToClosedAnimation!: Animation;
    private pageSlideToBackAnimation!: Animation;
    private pageSlideToFrontAnimation!: Animation;

    private actionManager!: ActionManager;

    private envelopeTurning = false;
    private envelopeTurned = false;
    private envelopeOpening = false;
    private envelopeOpened = false;

    /**
     * Create a new card renderer service
     *
     * @param container
     * @param serviceContainer
     * @param onReadyCallback
     */

    public constructor(
        container: HTMLDivElement,
        serviceContainer: ServiceContainer,
        onReadyCallback: () => void,
    )
    {
        this.container = container;
        this.serviceContainer = serviceContainer;
        this.createAnimations();
        this.createScene(onReadyCallback);
        this.createActions();

        // Inspector.Show(this.scene, {});
    }

    /**
     * Destroy the card renderer service
     */

    public destroy()
    {
        this.resizeObserver?.disconnect();
        this.resizeObserver = null;

        this.scene?.activeCamera?.detachControl();

        this.engine?.stopRenderLoop();
        this.engine?.dispose();

        this.canvas?.remove();
    }

    /**
     * Handles resize events
     */

    private onResize(width: number, height: number)
    {
        if (this.canvas)
        {
            this.canvas.width = width;
            this.canvas.height = height;
        }

        if (this.engine)
        {
            this.engine.resize();
        }
    }

    /**
     * Creates the main scene
     *
     * @param onReadyCallback
     */

    private async createScene(onReadyCallback: () => void)
    {
        // Create the canvas element
        this.canvas = document.createElement('canvas');
        this.canvas.width = this.container.clientWidth;
        this.canvas.height = this.container.clientHeight;
        this.container.appendChild(this.canvas);

        // Create the engine and scene
        this.engine = new Engine(this.canvas);
        this.scene = new Scene(this.engine);
        this.scene.clearColor = new Color4(
            0.75, 0.75, 0.75, 1,
        );

        // Add a resize observer to the container element
        this.resizeObserver = new ResizeObserver(entries =>
        {
            for (const entry of entries)
            {
                this.onResize(entry.contentRect.width, entry.contentRect.height);
            }
        });

        this.resizeObserver.observe(this.container);

        // Add main camera
        this.addCamera();

        // Add global light
        // this.addGlobalLight();

        // Set the render loop
        this.engine.runRenderLoop(() =>
        {
            this.onUpdate(this.engine.getDeltaTime());
        });

        // Set anti-aliasing
        const defaultPipeline = new DefaultRenderingPipeline(
            'default', true, this.scene, [ this.camera ],
        );

        defaultPipeline.fxaaEnabled = true;

        // Wait until the scene is ready
        await this.scene.whenReadyAsync();
        onReadyCallback();
    }

    /**
     * Initialises the card
     *
     * @param card
     * @param template
     * @param templateOverrides
     * @returns
     */

    public async init(
        card: Card,
        template: Template|null,
        templateOverrides: any,
    )
    {
        // Store the details locally
        this.card = card;
        this.template = template;
        this.templateOverrides = templateOverrides;

        // Preload the assets for the card
        await this.preloadAssets();

        // Load the paper material and store
        this.paperMaterial = this.loadMaterial('paper', false);
        const paperTexture = await this.loadTexture('paper', paperTextureImage);
        paperTexture.uScale = 6;
        paperTexture.vScale = 6;
        this.paperMaterial.diffuseTexture = paperTexture;

        // Create the container
        await this.createContainer();

        // Create the envelope
        await this.createEnvelope();

        // Create the card
        await this.createCard();
    }

    /**
     * Creates the container
     */

    private async createContainer()
    {
        // Create the container node
        const node = new TransformNode('container', this.scene);

        // Store a reference to the container node
        this.containerNode = node;
    }

    /**
     * Creates the envelope
     */

    private async createEnvelope()
    {
        // Create the container node
        const node = new TransformNode('envelope', this.scene);
        node.parent = this.containerNode;

        // Store a reference to the envelope node
        this.envelopeNode = node;

        // Create the front mesh
        this.createEnvelopeFront(node);

        // Create the back mesh
        this.createEnvelopeBack(node);
    }

    /**
     * Creates the front of the envelope
     *
     * @param envelopeNode
     */

    private async createEnvelopeFront(envelopeNode: TransformNode)
    {
        // Create the mesh for the front of the envelope
        const mesh = await this.createEnvelopeFrontMesh(envelopeNode);

        // Register interactions
        // const actionManager = new ActionManager(this.scene);
        mesh.actionManager = this.actionManager;

        // // Pointer over action
        // actionManager.registerAction(new ExecuteCodeAction(
        //     {
        //         trigger: ActionManager.OnPointerOverTrigger,
        //     },
        //     () =>
        //     {
        //         this.onPointerOverEnvelope();
        //     },
        //     new PredicateCondition(actionManager, () => this.envelopeTurning === false),
        // ));

        // // Pointer out action
        // actionManager.registerAction(new ExecuteCodeAction(
        //     {
        //         trigger: ActionManager.OnPointerOutTrigger,
        //     },
        //     () =>
        //     {
        //         this.onPointerOutEnvelope();
        //     },
        //     new PredicateCondition(actionManager, () => this.envelopeTurning === false),
        // ));

        // // Click action
        // actionManager.registerAction(new ExecuteCodeAction(
        //     {
        //         trigger: ActionManager.OnPickTrigger,
        //     },
        //     () =>
        //     {
        //         this.onClickEnvelope();
        //     },
        //     new PredicateCondition(actionManager, () => !!(this.envelopeTurning === false && this.envelopeOpened === false)),
        // ));
    }

    /**
     * Creates the front mesh for the envelope
     *
     * @param envelopeNode
     * @returns
     */

    private async createEnvelopeFrontMesh(envelopeNode: TransformNode): Promise<Mesh>
    {
        // Create the material for the front of the envelope
        const baseMesh = MeshBuilder.CreatePlane(
            'envelopeFrontBase', {
                width: CardRendererService.PAGE_HEIGHT,
                height: CardRendererService.PAGE_WIDTH,
            }, this.scene,
        );

        baseMesh.parent = envelopeNode;

        baseMesh.position = new Vector3(
            0, 0, -0.02,
        );

        baseMesh.material = this.paperMaterial;

        // Create the mesh
        const mesh = MeshBuilder.CreatePlane(
            'envelopeFront', {
                width: CardRendererService.PAGE_HEIGHT,
                height: CardRendererService.PAGE_WIDTH,
            }, this.scene,
        );

        mesh.parent = envelopeNode;

        // Mesh.material = this.paperMaterial;
        mesh.position = new Vector3(
            0, 0, -0.03,
        );

        // Create the material for the mesh
        const material = this.loadMaterial('envelopeFront');
        mesh.material = material;

        // Create the page content for the front of the envelope
        const dateElementDto = new ElementDto();
        dateElementDto.type = 'text';
        dateElementDto.config.text = {
            text: `Mail Received\n${ format(this.card.dateScheduled, 'dd/MM/yyyy') }\n${ format(this.card.dateScheduled, 'HH:mm') }`,
            font: 'VastShadow',
            colour: '#000000',
            size: '3',
        };
        dateElementDto.config.align = {
            horizontal: 'left',
            vertical: 'top',
        };
        dateElementDto.config.position = {
            x1: '2',
            x2: '30',
            y1: '2',
            y2: '20',
        };

        const stampElementDto = new ElementDto();
        stampElementDto.type = 'image';
        stampElementDto.url = christmasStampImage;
        stampElementDto.config.fit = 'contain';
        stampElementDto.config.align = {
            horizontal: 'right',
            vertical: 'top',
        };
        stampElementDto.config.position = {
            x1: '80',
            x2: '98',
            y1: '2',
            y2: '28',
        };

        const recipientElementDto = new ElementDto();
        recipientElementDto.type = 'text';
        recipientElementDto.config.text = {
            text: this.card.addressee,
            font: 'Arial',
            colour: '#000000',
            size: '4',
        };
        recipientElementDto.config.position = {
            x1: '0',
            x2: '100',
            y1: '0',
            y2: '100',
        };

        const layerDto = new LayerDto();
        layerDto.elements = [
            dateElementDto, stampElementDto, recipientElementDto,
        ];

        const pageDto = new PageDto();
        pageDto.layers = [ layerDto ];

        const page = new Page(pageDto);

        // Create the canvas for the front of the envelope
        const canvas = document.createElement('canvas');

        // Create a pixi renderer
        const pixiRenderer = new PixiRendererService(
            page,
            'front',
            [],
            CardRendererService.PAGE_HEIGHT_PIXELS,
            CardRendererService.PAGE_WIDTH_PIXELS,
            canvas,
        );

        // Initialise the pixi scene waiting for all elements (e.g. images) to load
        await pixiRenderer.init();

        // Create a dynamic texture for the layer
        const texture = new DynamicTexture(
            'envelopeFront',
            canvas,
            this.scene,
        );

        texture.hasAlpha = true;
        material.useAlphaFromDiffuseTexture = true;
        material.diffuseTexture = texture;

        // Render the content
        pixiRenderer.onUpdate(0);
        texture.update();
        pixiRenderer.deinit();

        return mesh;
    }

    /**
     * Creates the back of the envelope
     *
     * @param envelopeNode
     */

    private async createEnvelopeBack(envelopeNode: TransformNode)
    {
        // Create a container for the back of the envelope
        const backNode = new TransformNode('back', this.scene);
        backNode.parent = envelopeNode;

        backNode.position = new Vector3(
            0, 0, 0.02,
        );

        backNode.rotation = new Vector3(
            0, Tools.ToRadians(180), 0,
        );

        // Generate the mesh for the back of the envelope
        const halfWidth = CardRendererService.PAGE_WIDTH / 2;
        const halfHeight = CardRendererService.PAGE_HEIGHT / 2;

        const mainBodyPath = new Path2(-halfHeight, -halfWidth);
        mainBodyPath.addLineTo(-halfHeight, halfWidth);
        mainBodyPath.addLineTo(0, 0);
        mainBodyPath.addLineTo(halfHeight, halfWidth);
        mainBodyPath.addLineTo(halfHeight, -halfWidth);

        const mainBodyPolygon = new PolygonMeshBuilder(
            'back',
            mainBodyPath,
            this.scene,
            earcut,
        );

        const mainBodyMesh = mainBodyPolygon.build();

        // Apply the material to the mesh
        mainBodyMesh.material = this.paperMaterial;

        // Set the mesh's parent and position
        mainBodyMesh.parent = backNode;
        mainBodyMesh.isPickable = false;

        mainBodyMesh.rotation = new Vector3(
            Tools.ToRadians(270), 0, 0,
        );

        // Create a container for the flap of the envelope
        this.flapNode = new TransformNode('flap', this.scene);

        this.flapNode.setPivotPoint(new Vector3(
            0, halfWidth, 0,
        ));

        this.flapNode.parent = backNode;

        // Generate the mesh for the opening flap
        const flapPath = new Path2(-halfHeight, halfWidth);
        flapPath.addLineTo(halfHeight, halfWidth);
        flapPath.addLineTo(0, 0);

        const flapPolygon = new PolygonMeshBuilder(
            'flap',
            flapPath,
            this.scene,
            earcut,
        );

        const flapMesh = flapPolygon.build();
        flapMesh.material = this.paperMaterial;
        flapMesh.parent = this.flapNode;
        flapMesh.isPickable = false;
        flapMesh.rotation = new Vector3(
            Tools.ToRadians(270), 0, 0,
        );
    }

    /**
     * Creates the card
     */

    private async createCard()
    {
        // Create the container node
        const node = new TransformNode('card', this.scene);
        node.parent = this.containerNode;

        // Store a reference to the card node
        this.cardNode = node;

        // Initially rotate the card on its side by 90 degrees
        node.rotation = new Vector3(
            0, Tools.ToRadians(180), Tools.ToRadians(90),
        );

        // Add the pages to the card
        const reversePages = this.template?.pages
            .sort((pageA, pageB) => pageA.position - pageB.position)
            .slice()
            .reverse();

        for (const page of reversePages || [])
        {
            await this.createPage(page, node);
        }
    }

    /**
     * Handles click events on meshes
     *
     * @param event
     */

    private onClick(event: ActionEvent)
    {
        switch (event.source.name)
        {
        case 'envelopeFront':
            if (this.envelopeOpening || this.envelopeOpened)
            {
                return;
            }

            this.onClickEnvelope();
            break;

        default:
            if (this.envelopeOpening || !this.envelopeOpened)
            {
                return;
            }

            this.onClickPageSide(event.source.metadata.page, event.source.metadata.side);
        }
    }

    /**
     * Handles pointer over events on the envelope
     */

    private onPointerOverEnvelope()
    {
        if (this.envelopeTurned || this.envelopeOpened)
        {
            return;
        }

        this.envelopeTurned = true;
        this.envelopeTurning = true;

        this.scene.beginDirectAnimation(
            this.containerNode,
            [ this.envelopeFrontToBackAnimation ],
            0,
            CardRendererService.PAGE_TRANSITION_DURATION * CardRendererService.ANIMATION_FRAME_RATE,
            false,
            1,
            () =>
            {
                this.scene.beginDirectAnimation(
                    this.flapNode,
                    [ this.flapClosedToOpenAnimation ],
                    0,
                    CardRendererService.FLAP_TRANSITION_DURATION * CardRendererService.ANIMATION_FRAME_RATE,
                    false,
                    1,
                    () =>
                    {
                        this.envelopeTurning = false;
                        this.flapNode.position.z = 0.04;
                    },
                );
            },
        );
    }

    /**
     * Handles pointer out events on the envelope
     */

    private onPointerOutEnvelope()
    {
        if (!this.envelopeTurned || this.envelopeOpened)
        {
            return;
        }

        this.envelopeTurned = false;
        this.envelopeTurning = true;
        this.flapNode.position.z = 0;

        this.scene.beginDirectAnimation(
            this.flapNode,
            [ this.flapOpenToClosedAnimation ],
            0,
            CardRendererService.FLAP_TRANSITION_DURATION * CardRendererService.ANIMATION_FRAME_RATE,
            false,
            1,
            () =>
            {
                this.scene.beginDirectAnimation(
                    this.containerNode,
                    [ this.envelopeBackToFrontAnimation ],
                    0,
                    CardRendererService.PAGE_TRANSITION_DURATION * CardRendererService.ANIMATION_FRAME_RATE,
                    false,
                    1,
                    () =>
                    {
                        this.envelopeTurning = false;
                    },
                );
            },
        );
    }

    /**
     * Handles clicks on the envelope
     */

    private onClickEnvelope()
    {
        this.turnAndOpenEnvelope();

        // If (this.envelopeTurning)
        // {
        //     return;
        // }

        // if (this.envelopeTurned)
        // {
        //     this.onOpenEnvelope();
        // }
        // else
        // {
        //     this.onPointerOverEnvelope();
        // }
    }

    private onClickPageSide(page: Page, side: CardSide)
    {
        if (page.position > this.currentPage || (page.position === this.currentPage && side === 'front'))
        {
            this.openPage();
        }
        else
        {
            this.closePage();
        }
    }

    private turnAndOpenEnvelope()
    {
        this.envelopeOpened = true;

        this.scene.beginDirectAnimation(
            this.containerNode,
            [ this.envelopeFrontToBackAnimation ],
            0,
            CardRendererService.PAGE_TRANSITION_DURATION * CardRendererService.ANIMATION_FRAME_RATE,
            false,
            1,
            () =>
            {
                this.scene.beginDirectAnimation(
                    this.flapNode,
                    [ this.flapClosedToOpenAnimation ],
                    0,
                    CardRendererService.FLAP_TRANSITION_DURATION * CardRendererService.ANIMATION_FRAME_RATE,
                    false,
                    1,
                    () =>
                    {
                        this.envelopeTurning = false;
                        this.flapNode.position.z = 0.04;
                        this.onOpenEnvelope();
                    },
                );
            },
        );
    }

    /**
     * Handles the opening of the envelope
     */

    private onOpenEnvelope()
    {
        this.envelopeOpening = true;
        this.envelopeOpened = true;

        this.scene.beginDirectAnimation(
            this.envelopeNode,
            [ this.envelopeRemovalAnimation ],
            0,
            CardRendererService.FLAP_TRANSITION_DURATION * CardRendererService.ANIMATION_FRAME_RATE,
            false,
            1,
            () =>
            {
                this.scene.beginDirectAnimation(
                    this.cardNode,
                    [ this.cardTurnUprightAnimation ],
                    0,
                    CardRendererService.FLAP_TRANSITION_DURATION * CardRendererService.ANIMATION_FRAME_RATE,
                    false,
                    1,
                    () =>
                    {
                        this.envelopeOpening = false;
                        this.audio?.play();
                    },
                );
            },
        );
    }

    /**
     * Handles requests to open the page
     */

    public openPage()
    {
        if (!this.template || (this.currentSide === 'back' && this.currentPage === this.template.pages.length))
        {
            return;
        }

        let nextSide: CardSide;
        let nextPage: number;

        if (this.currentSide === 'front')
        {
            nextSide = 'back';
            nextPage = this.currentPage;

            // Open the page
            this.scene.beginDirectAnimation(
                this.pageNodes[nextPage], [ this.pageClosedToOpenAnimation ], 0, CardRendererService.PAGE_TRANSITION_DURATION * CardRendererService.ANIMATION_FRAME_RATE, false,
            );

            // Slide the card to centre the back of the page
            this.scene.beginDirectAnimation(
                this.cardNode, [ this.pageSlideToBackAnimation ], 0, CardRendererService.PAGE_TRANSITION_DURATION * CardRendererService.ANIMATION_FRAME_RATE, false,
            );
        }
        else
        {
            nextSide = 'front';
            nextPage = this.currentPage + 1;

            // Slide the card to centre the front of the page
            this.scene.beginDirectAnimation(
                this.cardNode, [ this.pageSlideToFrontAnimation ], 0, CardRendererService.PAGE_TRANSITION_DURATION * CardRendererService.ANIMATION_FRAME_RATE, false,
            );
        }

        this.currentSide = nextSide;
        this.currentPage = nextPage;
    }

    /**
     * Handles requests to close the page
     */

    public closePage()
    {
        if (!this.template || (this.currentSide === 'front' && this.currentPage === 1))
        {
            return;
        }

        let nextSide: CardSide;
        let nextPage: number;

        if (this.currentSide === 'back')
        {
            nextSide = 'front';
            nextPage = this.currentPage;

            // Close the page
            this.scene.beginDirectAnimation(
                this.pageNodes[nextPage], [ this.pageOpenToClosedAnimation ], 0, CardRendererService.PAGE_TRANSITION_DURATION * CardRendererService.ANIMATION_FRAME_RATE, false,
            );

            // Slide the card to centre the front of the page
            this.scene.beginDirectAnimation(
                this.cardNode, [ this.pageSlideToFrontAnimation ], 0, CardRendererService.PAGE_TRANSITION_DURATION * CardRendererService.ANIMATION_FRAME_RATE, false,
            );
        }
        else
        {
            nextSide = 'back';
            nextPage = this.currentPage - 1;

            // Slide the card to centre the back of the page
            this.scene.beginDirectAnimation(
                this.cardNode, [ this.pageSlideToBackAnimation ], 0, CardRendererService.PAGE_TRANSITION_DURATION * CardRendererService.ANIMATION_FRAME_RATE, false,
            );
        }

        this.currentSide = nextSide;
        this.currentPage = nextPage;
    }

    private createAnimations()
    {
        const easingFunction = this.createEasingFunction();

        this.envelopeFrontToBackAnimation = this.createPageRotationAnimation(
            'envelopeFrontToBack',
            Tools.ToRadians(0),
            Tools.ToRadians(180),
            CardRendererService.ANIMATION_FRAME_RATE,
            easingFunction,
        );

        this.envelopeBackToFrontAnimation = this.createPageRotationAnimation(
            'envelopeBackToFront',
            Tools.ToRadians(180),
            Tools.ToRadians(0),
            CardRendererService.ANIMATION_FRAME_RATE,
            easingFunction,
        );

        this.envelopeRemovalAnimation = this.createEnvelopeRemovalAnimation(CardRendererService.ANIMATION_FRAME_RATE, easingFunction);

        this.flapClosedToOpenAnimation = this.createFlapRotationAnimation(
            'flapClosedToOpen',
            Tools.ToRadians(0),
            Tools.ToRadians(180),
            CardRendererService.ANIMATION_FRAME_RATE,
            easingFunction,
        );

        this.flapOpenToClosedAnimation = this.createFlapRotationAnimation(
            'flapOpenToClosed',
            Tools.ToRadians(180),
            Tools.ToRadians(0),
            CardRendererService.ANIMATION_FRAME_RATE,
            easingFunction,
        );

        this.cardTurnUprightAnimation = this.createPageRotationAnimation(
            'cardTurnUpright',
            Tools.ToRadians(90),
            Tools.ToRadians(0),
            CardRendererService.ANIMATION_FRAME_RATE,
            easingFunction,
            'z',
        );

        this.pageClosedToOpenAnimation = this.createPageRotationAnimation(
            'pageClosedToOpen',
            Tools.ToRadians(0),
            Tools.ToRadians(180),
            CardRendererService.ANIMATION_FRAME_RATE,
            easingFunction,
        );

        this.pageOpenToClosedAnimation = this.createPageRotationAnimation(
            'pageOpenToClosed',
            Tools.ToRadians(180),
            Tools.ToRadians(0),
            CardRendererService.ANIMATION_FRAME_RATE,
            easingFunction,
        );

        this.pageSlideToBackAnimation = this.createCardSlideAnimation(
            'slideToBack',
            0,
            -CardRendererService.PAGE_WIDTH,
            CardRendererService.ANIMATION_FRAME_RATE,
            easingFunction,
        );

        this.pageSlideToFrontAnimation = this.createCardSlideAnimation(
            'slideToFront',
            -CardRendererService.PAGE_WIDTH,
            0,
            CardRendererService.ANIMATION_FRAME_RATE,
            easingFunction,
        );
    }

    private createFlapRotationAnimation(
        name: string,
        startRotation: number,
        endRotation: number,
        frameRate: number,
        easingFunction: EasingFunction,
    )
    {
        const animation = new Animation(
            name,
            'rotation.x',
            frameRate,
            Animation.ANIMATIONTYPE_FLOAT,
            Animation.ANIMATIONLOOPMODE_CONSTANT,
        );

        const activeAnimationKeys = [];

        activeAnimationKeys.push({
            frame: 0,
            value: startRotation,
        });

        activeAnimationKeys.push({
            frame: CardRendererService.PAGE_TRANSITION_DURATION * frameRate,
            value: endRotation,
        });

        animation.setKeys(activeAnimationKeys);
        animation.setEasingFunction(easingFunction);
        animation.enableBlending = true;

        return animation;
    }

    private createEnvelopeRemovalAnimation(frameRate: number, easingFunction: EasingFunction)
    {
        const animation = new Animation(
            'envelopeRemoval',
            'position.y',
            frameRate,
            Animation.ANIMATIONTYPE_FLOAT,
            Animation.ANIMATIONLOOPMODE_CONSTANT,
        );

        const activeAnimationKeys = [];

        activeAnimationKeys.push({
            frame: 0,
            value: 0,
        });

        activeAnimationKeys.push({
            frame: CardRendererService.PAGE_TRANSITION_DURATION * frameRate,
            value: -CardRendererService.PAGE_WIDTH * 3,
        });

        animation.setKeys(activeAnimationKeys);
        animation.setEasingFunction(easingFunction);

        return animation;
    }

    private createPageRotationAnimation(
        name: string,
        startRotation: number,
        endRotation: number,
        frameRate: number,
        easingFunction: EasingFunction,
        rotationAxis = 'y',
    )
    {
        const animation = new Animation(
            name,
            `rotation.${ rotationAxis }`,
            frameRate,
            Animation.ANIMATIONTYPE_FLOAT,
            Animation.ANIMATIONLOOPMODE_CONSTANT,
        );

        const activeAnimationKeys = [];

        activeAnimationKeys.push({
            frame: 0,
            value: startRotation,
        });

        activeAnimationKeys.push({
            frame: CardRendererService.PAGE_TRANSITION_DURATION * frameRate,
            value: endRotation,
        });

        animation.setKeys(activeAnimationKeys);
        animation.setEasingFunction(easingFunction);
        animation.enableBlending = true;

        return animation;
    }

    private createCardSlideAnimation(
        name: string,
        start: number,
        end: number,
        frameRate: number,
        easingFunction: EasingFunction,
    )
    {
        const animation = new Animation(
            name,
            'position.x',
            frameRate,
            Animation.ANIMATIONTYPE_FLOAT,
            Animation.ANIMATIONLOOPMODE_CONSTANT,
        );

        const activeAnimationKeys = [];

        activeAnimationKeys.push({
            frame: 0,
            value: start,
        });

        activeAnimationKeys.push({
            frame: CardRendererService.PAGE_TRANSITION_DURATION * frameRate,
            value: end,
        });

        animation.setKeys(activeAnimationKeys);
        animation.setEasingFunction(easingFunction);
        animation.enableBlending = true;

        return animation;
    }

    private createEasingFunction(): EasingFunction
    {
        const easingFunction = new BezierCurveEase(
            0.5, 0, 0.5, 1,
        );

        easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEIN);

        return easingFunction;
    }

    /**
     * Creates the actions for the scene
     */

    private createActions()
    {
        const actionManager = new ActionManager(this.scene);

        this.actionManager = actionManager;

        actionManager.registerAction(new ExecuteCodeAction({
            trigger: ActionManager.OnPickTrigger,
        },
        event =>
        {
            this.onClick(event);
        }));
    }

    /**
     * Preloads the assets used by the card
     *
     * @returns
     */

    private async preloadAssets()
    {
        const promises: Promise<unknown>[] = [];

        // Generic assets
        promises.push(this.loadTexture('paper', paperTextureImage));

        // Card specific assets
        for (const page of this.template?.pages || [])
        {
            for (const layer of page.layers)
            {
                for (const element of layer.elements)
                {
                    const preload = this.preloadElement(element);

                    if (preload)
                    {
                        promises.push(preload);
                    }
                }
            }
        }

        return Promise.all(promises);
    }

    /**
     * Preloads the items for a specific element
     *
     * @param element
     * @returns
     */

    private preloadElement(element: Element): Promise<Texture>|Promise<FontFace[]>|Promise<void>|null
    {
        switch (element.type)
        {
        case 'audio':
            if (element.asset?.path)
            {
                return this.loadAudio(element.id, AssetService.getAssetFileUrl(element.asset));
            }

            break;

        case 'image':
            if (element.asset?.path)
            {
                return this.loadImage(element.id, AssetService.getAssetFileUrl(element.asset));
            }

            break;

        case 'text':
            return this.loadFont(element.config.text?.font || 'Arial');
        }

        return null;
    }

    private audioConfigured = false;
    private audio: Sound|null = null;

    private async loadAudio(key: string, url: string): Promise<void>
    {
        if (this.audioConfigured)
        {
            return;
        }

        this.audioConfigured = true;

        this.audio = new Sound(
            key,
            url,
            this.scene,
            null,
        );

        if (Engine.audioEngine)
        {
            // Disable the default audio unlock button
            Engine.audioEngine.useCustomUnlockedButton = true;

            // Unlock audio on first user interaction.
            window.addEventListener(
                'click',
                () =>
                {
                    if (!Engine.audioEngine?.unlocked)
                    {
                        Engine.audioEngine?.unlock();
                    }
                },
                { once: true },
            );
        }
    }

    /**
     * Loads an image
     *
     * @param key
     * @param url
     */

    private async loadImage(key: string, url: string): Promise<void>
    {
        const response = await fetch(url);
        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(blob);

        this.images[key] = objectUrl;
    }

    /**
     * Loads a font
     *
     * @param font
     * @returns
     */

    private async loadFont(font: string): Promise<FontFace[]>
    {
        return document.fonts.load(`1em ${ font }`);
    }

    /**
     * Creates a page
     *
     * @param page
     * @param cardNode
     */

    private async createPage(page: Page, cardNode: TransformNode)
    {
        // Create the page node
        const node = new TransformNode(`page-${ page.position }`, this.scene);
        node.parent = cardNode;

        // Store a reference to the page node
        this.pageNodes[page.position] = node;

        // Set the pivot point of the node for rotation
        node.setPivotPoint(new Vector3(
            -CardRendererService.PAGE_WIDTH / 2, 0, 0,
        ));

        // If this is not the first page by position then rotate it back
        // if (page.position > 1)
        // {
        //     // Rotate on the y axis by 60 degrees
        //     node.rotation.y = Tools.ToRadians(-60);
        // }

        // Create the page mesh
        const pageMesh = MeshBuilder.CreatePlane(
            `page-${ page.position }`, {
                width: CardRendererService.PAGE_WIDTH,
                height: CardRendererService.PAGE_HEIGHT,
            }, this.scene,
        );

        pageMesh.parent = node;
        pageMesh.isPickable = false;
        pageMesh.material = this.paperMaterial;

        // Create the page content
        await this.createPageContent(page, node);
    }

    /**
     * Creates the content for a page
     *
     * @param page
     * @param pageNode
     */

    private async createPageContent(page: Page, pageNode: TransformNode)
    {
        for (const side of [ 'front', 'back' ])
        {
            // Add the content for this side
            await this.createPageSideContent(
                page,
                pageNode,
                side as CardSide,
            );
        }
    }

    /**
     * Creates the content for the side of a page
     *
     * @param page
     * @param pageNode
     * @param side
     */

    private async createPageSideContent(
        page: Page,
        pageNode: TransformNode,
        side: CardSide,
    )
    {
        const pageSideNode = new TransformNode(side, this.scene);
        pageSideNode.parent = pageNode;

        // Create the side plane
        const sideMesh = MeshBuilder.CreatePlane(
            `page-${ page.position }-${ side }`, {
                width: CardRendererService.PAGE_WIDTH,
                height: CardRendererService.PAGE_HEIGHT,
            }, this.scene,
        );

        sideMesh.parent = pageSideNode;

        sideMesh.metadata = {
            page,
            side,
        };

        sideMesh.actionManager = this.actionManager;

        // Set the position of the side
        sideMesh.position = new Vector3(
            0,
            0,
            side === 'front' ? -0.01 : 0.01,
        );

        if (side === 'back')
        {
            sideMesh.rotation = new Vector3(
                0, Tools.ToRadians(180), 0,
            );
        }

        // Set to hidden and go no further if this side has no elements
        // Note: Need the mesh to exist for the click events to work
        if (!this.sideHasElements(page, side))
        {
            sideMesh.visibility = 0;

            return;
        }

        // Create the material for the side
        const sideMaterial = this.loadMaterial(`page-${ page.position }-${ side }`);
        sideMesh.material = sideMaterial;

        const canvas = document.createElement('canvas');

        // Create a pixi renderer
        const pixiRenderer = new PixiRendererService(
            page,
            side,
            this.templateOverrides,
            CardRendererService.PAGE_WIDTH_PIXELS,
            CardRendererService.PAGE_HEIGHT_PIXELS,
            canvas,
        );

        // Initialise the pixi scene waiting for all elements (e.g. images) to load
        await pixiRenderer.init();

        // Create a dynamic texture for the layer
        const sideTexture = new DynamicTexture(
            `page-${ page.position }-${ side }`,
            canvas,
            this.scene,
        );

        sideTexture.hasAlpha = true;
        sideMaterial.diffuseTexture = sideTexture;

        // Render now or as part of render loop
        if (this.sideNeedsRenderLoop(page, side))
        {
            this.pixiRenderers.push({
                renderer: pixiRenderer,
                texture: sideTexture,
            });
        }
        else
        {
            pixiRenderer.onUpdate(0);
            sideTexture.update();
            pixiRenderer.deinit();
        }
    }

    /**
     * Returns whether the page side has elements
     *
     * @param page
     * @param side
     * @returns
     */

    private sideHasElements(page: Page, side: CardSide): boolean
    {
        for (const layer of page.layers)
        {
            if (layer.side === side && layer.elements.length > 0)
            {
                return true;
            }
        }

        return false;
    }

    /**
     * Returns whether the page side needs to be added to the render loop
     *
     * @param page
     * @param side
     * @returns
     */

    private sideNeedsRenderLoop(page: Page, side: CardSide): boolean
    {
        for (const layer of page.layers)
        {
            // Skip layers that are not on the current side
            if (layer.side !== side)
            {
                continue;
            }

            for (const element of layer.elements)
            {
                if (element.type === 'effect')
                {
                    return true;
                }
            }
        }

        return false;
    }

    /**
     * Processes updates for the current frame
     *
     * @returns
     */

    private onUpdate(delta: number)
    {
        if (!this.scene)
        {
            return;
        }

        // Render the scene
        this.scene.render();
        this.engine.wipeCaches(true);

        // Update the pixi renderers
        this.pixiRenderers.forEach(renderer =>
        {
            renderer.texture.update();
            renderer.renderer.onUpdate(delta);
        });
    }

    /**
     * Adds the main camera to the scene
     *
     * @returns
     */

    private addCamera()
    {
        if (!this.scene)
        {
            return;
        }

        this.camera = new ArcRotateCamera(
            'camera1',
            Tools.ToRadians(-90),

            // Tools.ToRadians(85),
            // 500,
            Tools.ToRadians(90),
            30,
            Vector3.Zero(),
            this.scene,
        );

        // This.camera.fov = 0.05;

        this.camera.setTarget(new Vector3(
            0,
            0,
            0,
        ));

        this.camera.minZ = 0;
        this.camera.maxZ = 100;

        // This.camera.attachControl(this.canvas, true);
    }

    /**
     * Adds a global light to the scene
     *
     * @returns
     */

    private addGlobalLight()
    {
        const mesh = MeshBuilder.CreatePlane(
            'wall', {
                width: 100,
                height: 100,
            }, this.scene,
        );

        mesh.position = new Vector3(
            0,
            0,
            5,
        );

        mesh.receiveShadows = true;

        const light = new DirectionalLight(
            'Light',
            new Vector3(
                0,
                0,
                1,
            ),
            this.scene,
        );

        light.intensity = 1;
        light.shadowMinZ = 0;
        light.shadowMaxZ = 100;
    }

    /**
     * Loads a material
     *
     * @param name
     * @param backFaceCulling
     * @returns
     */

    private loadMaterial(name: string, backFaceCulling = true): StandardMaterial
    {
        if (this.materials[name])
        {
            return this.materials[name];
        }

        const material = this.createStandardMaterial(name, backFaceCulling);
        this.materials[name] = material;

        return material;
    }

    private createStandardMaterial(name: string, backFaceCulling = true): StandardMaterial
    {
        const material = new StandardMaterial(name, this.scene);
        material.diffuseColor = Color3.White();
        material.specularColor = Color3.Black();
        material.emissiveColor = Color3.White();
        material.alpha = 1;
        material.useAlphaFromDiffuseTexture = true;
        material.backFaceCulling = backFaceCulling;

        return material;
    }

    /**
     * Loads a texture
     *
     * @param key
     * @param url
     * @returns
     */

    private async loadTexture(key: string, url: string): Promise<Texture>
    {
        if (this.textures[key])
        {
            return this.textures[key];
        }

        const texture = new Texture(url, this.scene);
        this.textures[key] = texture;

        // Promisify the onLoadObservable method
        return new Promise(resolve =>
        {
            texture.onLoadObservable.addOnce(() =>
            {
                resolve(texture);
            });
        });
    }

    /**
     * Returns a world quaternion from a vector interface
     *
     * @param vector
     * @returns
     */

    private static getQuaternionRotationFromVector(vector: Vector3): Quaternion
    {
        const angles = new Vector3(
            Tools.ToRadians(vector.x),
            Tools.ToRadians(vector.y),
            Tools.ToRadians(vector.z),
        );

        return Quaternion.FromEulerVector(angles);
    }

    /**
     * Returns the rotation vector from a quaternion
     *
     * @param quaternion
     * @returns
     */

    private static getVectorRotationFromQuaternion(quaternion: Quaternion): Vector3
    {
        const euler = quaternion.toEulerAngles();

        return new Vector3(
            Tools.ToDegrees(euler.x),
            Tools.ToDegrees(euler.y),
            Tools.ToDegrees(euler.z),
        );
    }
}