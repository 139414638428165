import * as PIXI from 'pixi.js';

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { CardSide } from '@/model/service/card/card-service';
import LayerComponent from '../layer/Layer.vue';
import Page from '@/model/entity/card/page';
import PixiRendererService from '@/model/service/card/pixi-renderer-service';
import { debounce } from 'lodash';

const ticker = new PIXI.Ticker();
ticker.start();

@Component({
    components:
    {
        'app-layer': LayerComponent,
    },
})
export default class PageComponent extends Vue
{
    @Prop()
    private page!: Page;

    @Prop()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private templateOverrides!: any;

    @Prop({
        type: Boolean,
        default: false,
    })
    private static!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    private turned!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    private front!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    private back!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    private hidden!: boolean;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public $refs: any = {
        page: HTMLElement,
        canvasFront: HTMLCanvasElement,
        canvasBack: HTMLCanvasElement,
    };

    private sides: CardSide[] = [
        'front',
        'back',
    ];

    private pixiFront!: PixiRendererService;
    private pixiBack!: PixiRendererService;

    private pageWidth = 0;
    private pageHeight = 0;

    private mounted()
    {
        // Update page size
        this.pageWidth = this.$refs.page.offsetWidth;
        this.pageHeight = this.$refs.page.offsetHeight;

        // Initialise Pixi
        this.initPixi();
    }

    private destroyed()
    {
        this.deinitPixi();
    }

    @Watch('page')
    private debouncedOnPageChange = debounce(this.onPageChange, 250);

    private onPageChange(newPage: Page, oldPage: Page)
    {
        // Only process if it's actually changed
        if (JSON.stringify(newPage.dto) === JSON.stringify(oldPage.dto))
        {
            return;
        }

        this.reinitPixi();
    }

    @Watch('$screen.width')
    private onWindowWidthChange()
    {
        this.onWindowResize();
    }

    @Watch('$screen.height')
    private onWindowHeightChange()
    {
        this.onWindowResize();
    }

    /**
     * Handles window resize events
     */

    private onWindowResize()
    {
        // Update page size
        this.pageWidth = this.$refs.page.offsetWidth;
        this.pageHeight = this.$refs.page.offsetHeight;

        // Re-init pixi
        this.reinitPixi();
    }

    /**
     * Returns whether the given side of the page should be generated with pixi
     *
     * @param side
     * @returns
     */

    private sideIsPixi(side: string): boolean
    {
        if (this.static)
        {
            return false;
        }

        for (const layer of this.page.layers)
        {
            if (layer.side !== side)
            {
                continue;
            }

            for (const element of layer.elements)
            {
                if (element.type === 'effect')
                {
                    return true;
                }
            }
        }

        return false;
    }

    private getCanvasForSide(side: string): HTMLCanvasElement|null
    {
        if (!this.sideIsPixi(side))
        {
            return null;
        }

        return side === 'front' ? this.$refs.canvasFront[0] : this.$refs.canvasBack[0];
    }

    /**
     * Initialises Pixi rendering of the page
     */

    private async initPixi()
    {
        this.initSidePixi('front');
        this.initSidePixi('back');
    }

    /**
     * Re-initialises pixi instances
     */

    private reinitPixi()
    {
        if (this.pixiFront)
        {
            this.pixiFront.reinit();
        }

        if (this.pixiBack)
        {
            this.pixiBack.reinit();
        }
    }

    /**
     * De-initialises pixi instances
     */

    private deinitPixi()
    {
        if (this.pixiFront)
        {
            this.pixiFront.deinit();
        }

        if (this.pixiBack)
        {
            this.pixiBack.deinit();
        }
    }

    /**
     * Inits pixi for a given side
     *
     * @param side
     * @returns
     */

    private initSidePixi(side: CardSide)
    {
        if (!this.sideIsPixi(side))
        {
            return;
        }

        try
        {
            // Get the canvas
            const canvas = this.getCanvasForSide(side);
            if (!canvas)
            {
                return;
            }

            // Create a pixi renderer
            const pixiRenderer = new PixiRendererService(
                this.page,
                side,
                this.templateOverrides,
                this.pageWidth,
                this.pageHeight,
                canvas,
            );

            pixiRenderer.init();

            if (side === 'front')
            {
                this.pixiFront = pixiRenderer;
            }
            else
            {
                this.pixiBack = pixiRenderer;
            }

            // Allow touch bubbling to the DOM
            pixiRenderer.getRenderer().plugins.interaction.autoPreventDefault = false;

            // Add to ticker
            ticker.add(() =>
            {
                pixiRenderer.onUpdate(ticker.deltaMS);
            });
        }
        catch (err)
        {
            console.error(err);
        }
    }

    /**
     * Handles side click action
     *
     * @param side
     */

    private onSideClicked(side: string)
    {
        this.$emit('side-clicked', side);
    }
}