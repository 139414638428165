import { Component, Prop, Watch } from 'vue-property-decorator';
import { find, findIndex, isEqual } from 'lodash';

import Card from '../card/Card.vue';
import { CardSide } from '@/model/service/card/card-service';
import Element from '@/model/entity/card/element';
import ElementEditor from '../element-editor/ElementEditor.vue';
import { ElementType } from '@/model/service/card/template-service';
import Layer from '@/model/entity/card/layer';
import Loader from '../loader/Loader.vue';
import Page from '@/model/entity/card/page';
import PageLayers from '../page-layers/PageLayers.vue';
import SearchMixin from '@/mixins/search-mixin';
import Template from '@/model/entity/card/template';
import TemplateForm from '../template-form/TemplateForm.vue';
import { mixins } from 'vue-class-component';

@Component({
    components:
    {
        'app-card': Card,
        'app-element-editor': ElementEditor,
        'app-loader': Loader,
        'app-page-layers': PageLayers,
        'app-template-form': TemplateForm,
    },
})
export default class TemplateEditorComponent extends mixins(SearchMixin)
{
    @Prop()
    private value!: Template;

    @Prop({
        type: Object,
        default: null,
    })
    private original!: Template|null;

    private template: Template|null = null;

    private currentPage = 1;
    private currentSide: CardSide = 'front';
    private currentLayer: string|null = null;
    private currentElement: string|null = null;

    private isDirty = false;

    private created()
    {
        this.setTemplate(this.value);
    }

    @Watch('value')
    private onValueChanged()
    {
        this.setTemplate(this.value);
    }

    @Watch('template.categories')
    private onCategoriesUpdated()
    {
        if (!this.template)
        {
            return;
        }

        if (!isEqual(this.template.categorySlugs, this.original?.categorySlugs))
        {
            this.isDirty = true;
        }
    }

    @Watch('template.tags')
    private onTagsUpdated()
    {
        if (!this.template)
        {
            return;
        }

        if (!isEqual(this.template.tagTitles, this.original?.tagTitles))
        {
            this.isDirty = true;
        }
    }

    /**
     * Sets the current template
     *
     * @param template
     */

    private setTemplate(template: Template)
    {
        this.template = template;

        this.currentPage = 1;
        this.currentSide = 'front';
        this.currentLayer = null;
        this.currentElement = null;
        this.isDirty = false;
    }

    /**
     * Handles value updates
     */

    private onUpdate()
    {
        this.$emit('input', this.template);
    }

    /**
     * Returns whether the card should be book-like
     */

    private get bookLike(): boolean
    {
        return false;
    }

    /**
     * Returns the current page
     */

    private get page(): Page|null
    {
        if (!this.template || !this.template.pages?.[this.currentPage - 1])
        {
            return null;
        }

        return this.template.pages[this.currentPage - 1];
    }

    /**
     * Returns the current page's layers for the current side
     */

    private get pageLayers(): Layer[]
    {
        if (!this.page)
        {
            return [];
        }

        return this.page.getSideLayers(this.currentSide);
    }

    /**
     * Returns the current element
     */

    private get element(): Element|null
    {
        if (!this.template || !this.page || !this.currentElement)
        {
            return null;
        }

        for (const layer of this.page.layers)
        {
            const element = find(layer.elements, { id: this.currentElement });
            if (element)
            {
                return element;
            }
        }

        return null;
    }

    /**
     * Sets the current element
     */

    private set element(element: Element|null)
    {
        if (!element)
        {
            return;
        }

        this.updateElement(element);

        this.isDirty = true;
    }

    /**
     * Handles page changes
     *
     * @param page
     * @param side
     */

    private onPageChanged(page: number, side: CardSide)
    {
        this.currentPage = page;
        this.currentSide = side;
    }

    /**
     * Handles layer changes
     *
     * @param id
     */

    private onLayerChanged(id: string|null)
    {
        this.currentLayer = id;
    }

    /**
     * Handles element changes
     *
     * @param id
     */

    private onElementChanged(id: string|null)
    {
        this.currentElement = id;
    }

    /**
     * Handles template updates from the form
     */

    private onTemplateUpdated()
    {
        this.isDirty = true;
    }

    /**
     * Handles page creation
     *
     * @returns
     */

    private async onAddPage()
    {
        if (!this.template)
        {
            return;
        }

        this.template.createPage();

        this.isDirty = true;
    }

    private async onDeletePage()
    {
        if (!this.template || !this.page)
        {
            return;
        }

        this.template.deletePage(this.page.id);

        this.isDirty = true;
    }

    /**
     * Handles layer creation
     */

    private async onAddLayer()
    {
        if (!this.page)
        {
            return;
        }

        this.page.createLayer(this.currentSide);
        this.updatePage(this.page);

        this.isDirty = true;
    }

    /**
     * Handles layer movements
     *
     * @param id
     * @param direction
     */

    private async onMoveLayer(id: string, direction: string)
    {
        if (!this.page)
        {
            return;
        }

        this.page.moveLayer(id, direction);
        this.updatePage(this.page);

        this.isDirty = true;
    }

    /**
     * Handles layer deletion
     *
     * @param id
     */

    private async onDeleteLayer(id: string)
    {
        if (!this.page)
        {
            return;
        }

        this.page.deleteLayer(id);
        this.updatePage(this.page);

        this.isDirty = true;
    }

    /**
     * Handles element creation
     *
     * @param layerId
     * @param type
     */

    private async onAddElement(layerId: string, type: ElementType)
    {
        if (!this.template || !this.page)
        {
            return;
        }

        const layer = this.page.getLayer(layerId);
        if (!layer)
        {
            return;
        }

        layer.createElement(type);
        this.updateLayer(layer);

        this.isDirty = true;
    }

    /**
     * Handles element deletion
     *
     * @param id
     */

    private async onDeleteElement(id: string)
    {
        if (!this.page)
        {
            return;
        }

        for (const layer of this.page.layers)
        {
            const elementIndex = findIndex(layer.elements, { id });
            if (elementIndex < 0)
            {
                continue;
            }

            layer.deleteElement(id);
            this.updateLayer(layer);

            this.isDirty = true;

            break;
        }
    }

    /**
     * Saves the changes to the database
     */

    private async onSave()
    {
        if (!this.template)
        {
            return;
        }

        this.template = await this.$serviceContainer.templateService.saveTemplateChanges(this.template, this.original);
        this.isDirty = false;

        this.onUpdate();
        this.$emit('saved');
    }

    /**
     * Updates the page in the current template
     *
     * @param page
     * @returns
     */

    private updatePage(page: Page)
    {
        if (!this.template)
        {
            return;
        }

        // Update the page in the template
        this.template.updatePage(page);
    }

    /**
     * Updates the layer in it's page
     *
     * @param layer
     * @returns
     */

    private updateLayer(layer: Layer)
    {
        if (!this.template || !this.page)
        {
            return;
        }

        // Get the page
        const page = find(this.template.pages, (page: Page) => (!!page.getLayer(layer.id)));
        if (!page)
        {
            return;
        }

        // Update the layer in the page
        page.updateLayer(layer);

        // Update the page in the template
        this.updatePage(page);
    }

    /**
     * Updates the element in it's layer
     *
     * @param element
     * @returns
     */

    private updateElement(element: Element)
    {
        if (!this.template || !this.page)
        {
            return;
        }

        // Get the layer
        const layer = find(this.page.layers, (layer: Layer) => (!!layer.getElement(element.id)));
        if (!layer)
        {
            return;
        }

        // Update the element in the layer
        layer.updateElement(element);

        // Updaye the layer in the page
        this.updateLayer(layer);
    }
}