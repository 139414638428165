import { Component, Vue, Watch } from 'vue-property-decorator';

import Card from '@/model/entity/card/card';
import Card3dComponent from '@/components/card-3d//Card3d.vue';
import CardComponent from '@/components/card/Card.vue';
import EnvelopeComponent from '@/components/envelope/Envelope.vue';

@Component({
    components: {
        'app-card': CardComponent,
        'app-card-3d': Card3dComponent,
        'app-envelope': EnvelopeComponent,
    },
})
export default class CardView extends Vue
{
    private card: Card|null = null;
    private opened = false;

    created(): void
    {
        this.loadCard();
    }

    @Watch('$route')
    private onRouteChanged()
    {
        // If the id has changed, the card has changed
        if (this.$route.params.id !== this.card?.id)
        {
            this.loadCard();
        }
    }

    /**
     * Returns whether the card should be rendered in 3D
     */

    private get render3d(): boolean
    {
        // Firefox currently has an issue with Dynamic Textures in Babylon JS due to the rendering speed of WebGL2RenderingContext.texImage2D
        // See: https://forum.babylonjs.com/t/performance-of-updating-dynamic-texture-on-firefox/47624
        const isFirefox = navigator.userAgent.toLowerCase().includes('firefox');
        if (isFirefox)
        {
            return false;
        }

        return this.card?.template?.render3d ?? false;
    }

    /**
     * Returns whether the card should be book-like
     */

    private get bookLike(): boolean
    {
        return this.$screen.width >= 1024;
    }

    /**
     * Loads the current card
     */

    private async loadCard()
    {
        const card = await this.$serviceContainer.cardService.loadCard(this.$route.params.id);
        if (!card)
        {
            return;
        }

        // Set the card
        this.card = card;
    }

    /**
     * Handles opening the envelope
     */

    private onEnvelopeOpened()
    {
        this.opened = true;
    }
}